import * as React from "react"
import Layout from "../layout/Layout"
import SEO from "../components/SEO"
import fabricsOfIndia from "../images/fabricsOfIndia.png"
import { useStaticQuery, navigate, graphql } from "gatsby"

import "./fabricsOfIndia.scss"
import { slugReplace } from "../components/Reel"

// TODO: Refactor so the counts is happening on the graphql side,
// sometime when the page is generated in stead of in the browser
const ListOfStates = ({ allStates, forCounts }) => {
  const exists = allStates && allStates.edges.length > 0
  let counts = {}
  if (forCounts?.edges) {
    for (const i of forCounts.edges) {
      if (i?.node.frontmatter.state.length > 0) {
        i.node.frontmatter.state.map((i) => {
          if (counts[i]) {
            counts[i] = counts[i] + 1
          } else {
            counts[i] = 1
          }
          return null
        })
      }
    }
  }
  if (!exists) return null
  return (
    <ul id="state-list">
      {allStates.edges.map((i) => {
        const frontmatter = i.node.frontmatter
        const count = counts[frontmatter.title]
        const slug = slugReplace(i.node.fields.slug)
        if (!count) return null
        return (
          <li key={frontmatter.title}>
            <div
              role="button"
              tabIndex={0}
              onClick={() => navigate(slug)}
              onKeyDown={() => navigate(slug)}
              className="state-card"
            >
              <div className="text-container">
                <span className="title">{frontmatter.title}</span>
                <span className="count">
                  {" "}
                  {count ?? 0} fabric{count === 1 ? "" : "s"}
                </span>
              </div>
              {frontmatter.thumbnail && (
                <div className="image-container">
                  <img src={frontmatter.thumbnail} alt={frontmatter.title} />
                </div>
              )}
            </div>
          </li>
        )
      })}
    </ul>
  )
}

const IndiaMap = () => {
  return (
    <div className="right-container">
      <img src={fabricsOfIndia} alt="Fabrics Of India" />
    </div>
  )
}

const FabricsOfIndia = ({ unlock, ...props }) => {
  const title = "Fabricsthan | Fabrics Of India"
  const { allStates, forCounts } = useStaticQuery(graphql`
    query States {
      allStates: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/state/" } }
        sort: { fields: frontmatter___title }
      ) {
        totalCount
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              thumbnail
            }
          }
        }
      }
      forCounts: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/fabric/.*.md/" } }
      ) {
        totalCount
        edges {
          node {
            frontmatter {
              state
            }
          }
        }
      }
    }
  `)

  const SEO_DESCRIPTION =
    "Learn about the gorgeous fabrics and their journey from fiber to fabric, which this land of vivid culture and religions has to offer. Every corner of India has distinctive yet beautiful apparel to showcase."

  return (
    <Layout bgAlt={title}>
      <SEO title={title} description={SEO_DESCRIPTION} image={fabricsOfIndia} />
      <div id="fabrics-of-india-page" className="container">
        <div className="left-container">
          <h2>Select a state from the list below</h2>
          <p>
            To information about the state and view all the fabrics made in that
            state, click on one of the states
          </p>
          <ListOfStates allStates={allStates} forCounts={forCounts} />
        </div>
        <IndiaMap />
      </div>
    </Layout>
  )
}

export default FabricsOfIndia
